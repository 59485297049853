import { Box } from "@material-ui/core"
import { RankingModules } from "./RankingModule"

export const RecentlyPage = () => {
    return (
        <Box mt={2}>
            <RankingModules category='date' title='最近追加されたクイズ' backgroundColor='#2B8BA8' size={100} />
        </Box>
    )
}

export const GoodCountPage = () => {
    return (
        <Box mt={2}>
            <RankingModules category='count' title='いいねされたクイズ' backgroundColor='#2B8BA8' size={100} />
        </Box>
    )
}