import React, { useEffect } from 'react';
declare global { interface Window { adsbygoogle: any } }
type Props = {}
const GoogleAd: React.FC<Props> = function () {
    useEffect(() => {
        if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
            window.adsbygoogle.push({});
        }
    }, [])
    return (
        <ins className="adsbygoogle"
            style={{ display: "inline-block", width: "300px", height: "250px" }}
            data-ad-client="ca-pub-5343385158896598"
            data-ad-slot="6875719052"></ins>
    );
};
export default GoogleAd;