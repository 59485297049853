import React, { useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

interface ChartPropsInterface {
    title: string,
    value: number
}
type Props = {
    data: ChartPropsInterface[];
};

export const CircleChart = (props: Props) => {
    const valueList: number[] = props.data.map((element) => element.value)
    const [selected, setSelected] = useState<number>(valueList.indexOf(Math.max(...valueList)));

    const colorSet = ["#0EB49A", "#2B8BA8", "#A85F2B", "#8CB309", "#0EB49A", "#2B8BA8", "#A85F2B", "#8CB309", "#0EB49A", "#2B8BA8", "#A85F2B", "#8CB309"]

    const data = props.data.map((entry, i) => {
        return {
            ...entry,
            color: colorSet[i],
            key: entry.title,
        };
    });


    return (
        <PieChart
            style={{
                fontFamily:
                    '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                fontSize: '8px',
            }}
            data={data}
            radius={PieChart.defaultProps.radius - 10}
            lineWidth={45}
            animate
            paddingAngle={2}
            center={[50, 50]}
            segmentsShift={(index) => (index === selected ? 1 : 0)}
            label={({ x, y, dx, dy, dataEntry }) => (
                <text
                    dominantBaseline="central"
                    textAnchor="middle"
                    key={dataEntry.key}
                >
                    <tspan x={50} y={45}>{`${(dataEntry.title === data[selected].title) ? dataEntry.title : ""}`}</tspan>
                    <tspan x={50} y={55}> {`${(dataEntry.title === data[selected].title) ? Math.round(dataEntry.percentage).toString() + "%" : ""} `}</tspan>
                </text>
            )}
            labelPosition={0}
            labelStyle={{
                fill: '#000',
                opacity: 0.75,
                pointerEvents: 'none',
            }}
            onClick={(_, index) => {
                setSelected(index === selected ? 0 : index);
            }}
        />
    );
}