import { Box, Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { QuizDataStore, QuizForm } from "./CreateQuiz";
import BackGround from '../img/back.png'
import { Audio } from "../answer/QuizUi";

export interface CardConfirmUIProp {
    data: QuizDataStore,
    changeAfterEditMode: (number: number) => void,
    defaultIndex: number
}

export let CardConfirmUI = (props: CardConfirmUIProp) => {
    return (
        <div >
            <SwipeableViews
                index={props.defaultIndex}
                enableMouseEvents>
                {props.data.quiz.map((element, index) => {
                    return <div key={index} >
                        <span style={{ borderStyle: "none", paddingInline: "5px", paddingInlineStart: "20px", textAlign: "left" }}>
                            <Number currentNumber={index + 1} endNumber={props.data.quiz.length} />
                        </span>
                        <Button style={{ textAlign: "right" }} color="primary" aria-label="edit" variant="contained" onClick={() => { props.changeAfterEditMode(index) }}><Edit />
                        </Button>
                        <Card quizForm={element} />
                    </div>
                })}
            </SwipeableViews>


        </div>
    )
}

type CardProps = {
    quizForm: QuizForm
}

const Card = (props: CardProps) => {

    return (
        <div>
            <div id="box" style={{ paddingTop: "15px", paddingBottom: "10px", backgroundColor: "#0EB49A", borderRadius: "15px", textAlign: "center" }}>

                <div style={{ backgroundImage: `url(${BackGround})`, backgroundSize: "cover", marginInline: "7vw", marginBlock: "10px", paddingBottom: "30px", borderRadius: "14px" }}>
                    <Box component="div" style={{ font: "normal normal normal 7vw/10vw Hiragino Kaku Gothic ProN" }} pt="30px" pr="15px" pl="15px">{props.quizForm.question}</Box>
                    {props.quizForm.mode === "audio" && <Audio audioFileName={props.quizForm.audioBlobUrl ?? ""} />}
                    {props.quizForm.mode === "image" && props.quizForm.imageResource != null && <Image file={props.quizForm.imageResource} />}
                </div>
                {props.quizForm.answer.map(it => <QuestionButton statement={it} isAnswer={true} key={it} />)}
                {props.quizForm.notAnswer.map(it => <QuestionButton statement={it} isAnswer={false} key={it} />)}
            </div>

        </div >
    )
}

interface NumberProps {
    currentNumber: number,
    endNumber: number
}

const Number = (props: NumberProps) => {
    return (
        <span style={{ font: "normal normal normal 30px/50px Heisei Maru Gothic Std", color: "#000000" }}>
            <span>
                第{props.currentNumber}問</span><span style={{ fontSize: "70%" }}> /{props.endNumber}問</span></span>
    )
}

interface ImageProps {
    file: File
}

const Image = (props: ImageProps) => {
    const [state, setstate] = useState("")
    let reader = new FileReader();
    reader.onloadend = () => {
        setstate(reader.result as string)
    }
    reader.readAsDataURL(props.file);
    // let url = reader.result ?? "";
    return (
        <Box mt="20px">
            <img src={state} width="250px" alt="クイズ画像" />
        </Box>
    )
}

type QustionButtonPropsInterface = {
    statement: string
    isAnswer: boolean
}

const QuestionButton = (props: QustionButtonPropsInterface) => {
    let color = "#ffffff";
    if (props.isAnswer) {
        color = "#ff0000"
    }
    return (
        <div style={{ marginInline: "20px", marginBlock: "15px" }}>
            <div
                style={{
                    backgroundColor: "#FFFFFF5D", borderRadius: "12px",
                    font: "normal normal bold 25px/40px Arial"
                }}><span style={{ color: color }}>{props.statement}</span>
            </div>
        </div >
    )
}