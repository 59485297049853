import { createMuiTheme } from '@material-ui/core/styles';

export const thema = createMuiTheme({
    palette: {
        primary: {
            light: "#23C4FA",
            main: '#0EB49A',
            dark: '#002884',
            contrastText: '#fff',
        },
        secondary: {
            main: '#2B8BA8',
            contrastText: '#fff',
        },
        info: {
            main: '#A85F2B',
            contrastText: '#fff',
        },
        success: {
            main: '#8CB309'
        }

    },
});