import React from "react"
import { Grid, IconButton, InputBase, Paper } from "@material-ui/core"
import ClearIcon from '@material-ui/icons/Clear';
interface EnterChoseBoxFormProps {
    answer: string,
    label: string,
    setAnswer: (arg: string) => void
    setDisable: () => void
}
export let EnterChoseBoxForm = (props: EnterChoseBoxFormProps) => {
    let onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setAnswer(e.target.value)
    }
    return (
        <Paper style={{ minWidth: 120, marginBlock: "10px", borderRadius: "10px", boxShadow: "none" }}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={9} style={{ marginInlineStart: "10px" }}>
                    <InputBase
                        value={props.answer}
                        placeholder={props.label}
                        onChange={onChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <IconButton onClick={() => {
                        props.setDisable()
                    }}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}