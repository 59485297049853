import { useState } from "react";

interface ImageModeProps {
    setImageResource: (file: File) => void
    defaultImage: File | undefined
}
export let ImageMode = (props: ImageModeProps) => {
    const [image, setimage] = useState<string>("")

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        const file = event.target.files[0];
        if (file === null) {
            return;
        }
        setViewImage(file)
    }
    let setViewImage = (file: File) => {
        let reader = new FileReader()
        reader.onload = (e) => {
            setimage(e.target?.result as string ?? "")
            props.setImageResource(file)
        };
        reader.readAsDataURL(file)
    }
    if (props.defaultImage !== undefined) {
        setViewImage(props.defaultImage)
    }
    return (
        <div>
            <input
                type="file"
                accept="image/*"
                onChange={onFileInputChange}
            />
            { image !== "" && <img src={image} alt="imageFile" style={{ width: "100%" }
            } />}

        </div>
    )

}
