import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyDhWgGH3RNE9NGkIe9-Opn0ubg8fQdrJrk",
  authDomain: "quiz-25aae.firebaseapp.com",
  projectId: "quiz-25aae",
  storageBucket: "quiz-25aae.appspot.com",
  messagingSenderId: "960097458176",
  appId: "1:960097458176:web:c1eadd52888d18e70760e5",
  measurementId: "G-RP85YPZ850"
};
firebase.initializeApp(firebaseConfig);
export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const provider = new firebase.auth.TwitterAuthProvider();
// 本番
export const functions = firebase.functions();

// エミュレータ用
// export const functions = firebase.functions().useEmulator("localhost", 5001);