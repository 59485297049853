import { Box, Button, CircularProgress, MuiThemeProvider, TextField } from "@material-ui/core"
import { db, functions, storage } from "../Firebase"
import { QuizDataStore } from "./CreateQuiz"
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useAppContext } from "../App"
import { CardConfirmUI } from "./CardConfirmUI";
import { thema } from "../thema"
import BackGround from '../img/back.png'


interface ConfirmProps {
    data: QuizDataStore,
    title: string,
    changeTitle: (value: string) => void,
    changeAddEditMode: () => void,
    changeAfterEditMode: (arg: number) => void,
    defaultIndex: number,
}
//answerLogic.tsを参考に
interface NewQuizDataForBE {
    user: string,
    userPhoto: string,
    uid: string,
    uuid: string,
    title: string,
    date: number,
    quizNumber: number
}
export let Confirm = (props: ConfirmProps) => {
    const history = useHistory()
    const [isloading, setisloading] = useState(false)
    const { uuid, userName, userPhoto } = useAppContext()
    const [title, settitle] = useState(props.title)
    let titleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        settitle(e.target.value)
        props.changeTitle(e.target.value)
    }

    useEffect(() => {
        window.history.pushState(null, "state", window.location.href);
        window.addEventListener('popstate', onUnBack);
        window.addEventListener('beforeunload', onUnload);

        return () => {
            window.removeEventListener('beforeunload', onUnload);
            window.removeEventListener('popstate', onUnBack);
        }
    }, [props.data])

    const onUnBack = (e: any) => {
        alert('データが消えてしまうため、ブラウザバックを使わないでください。');
        window.history.go(1);
    }

    const onUnload = (e: { preventDefault: () => void; returnValue: string; }) => {
        e.preventDefault();
        e.returnValue = '';
    }

    let upload = async () => {
        setisloading(true)
        let asyncProcess: Promise<any>[] = []
        const dateId = Date.now()
        const documentUid = dateId + uuid
        const newDataForBE: NewQuizDataForBE = {
            user: userName,
            userPhoto: userPhoto,
            uid: documentUid,
            uuid: uuid,
            title: title,
            date: dateId,
            quizNumber: props.data.quiz.length
        }
        asyncProcess.push(functions.httpsCallable("ogpCreate-ogpCreate")({ title: title, quizId: documentUid }))
        asyncProcess.push(functions.httpsCallable("answerLogic-newQuestion")(newDataForBE))
        let uploadedQuizDataStore = await Promise.all(props.data.quiz.map(async (element, index) => {
            let blobFileName
            switch (element.mode) {
                case "audio":
                    if (element.audioBlobUrl !== undefined) {
                        blobFileName = await uploadBlob(element.audioBlobUrl, uuid, index)
                    }
                    break
                case "image":
                    if (element.imageResource !== undefined) {
                        blobFileName = await uploadImage(element.imageResource, uuid)
                    }
                    break
                case "text":
                    blobFileName = ""
                    break
            }
            return {
                fileName: blobFileName,
                answer: element.answer,
                notAnswer: element.notAnswer,
                mode: element.mode,
                question: element.question,
                date: dateId
            }
        }))

        const ref = db.collection("quiz").doc("quiz").collection(uuid).doc(documentUid)
        asyncProcess.push(ref.set({ data: uploadedQuizDataStore, date: dateId, title: title, userPhoto: userPhoto, uuid: uuid }))
        Promise.all(asyncProcess).then(() => {
            history.push("/")
        }).catch((e) => {
            console.log(e)
        })
    }
    return (
        <MuiThemeProvider theme={thema}>

            <div style={{
                backgroundColor: "white",
                borderWidth: "5px",
                border: "solid",
                borderColor: thema.palette.primary.main,
                marginInline: "30px", marginTop: "10px", borderRadius: "10px", textAlign: "center", paddingTop: "5px", paddingBottom: "10px", paddingInline: "20px"
            }}>
                <div style={{ backgroundColor: "#0b907b", marginInline: "0px", borderRadius: "10px", marginBlock: "10px", textAlign: "center" }}>
                    <div style={{ color: "white", padding: "5px", fontSize: "26px" }}>クイズの作成</div>
                </div>
                <Box style={{ backgroundImage: `url(${BackGround})`, backgroundSize: "cover", fontSize: "35px", paddingBlock: "10px", borderRadius: "10px", paddingInline: "20px", marginBlock: "5px" }}>
                    <TextField label="タイトルを入力" variant="outlined" fullWidth onChange={titleChange} value={title} />
                </Box>
                <div style={{ marginBlock: "5px" }}>
                    <CardConfirmUI defaultIndex={props.defaultIndex} changeAfterEditMode={(arg: number) => props.changeAfterEditMode(arg)} data={props.data} />
                    {
                        title === "" ? <div style={{ color: "red", fontSize: "20px", marginBlockStart: "10px" }}>タイトルを入力してください</div> : <Button onClick={props.changeAddEditMode} fullWidth style={{ borderRadius: "15px", backgroundColor: thema.palette.primary.main, boxShadow: "0px 2px 6px #00000029", color: "white", fontSize: "20px", marginBlockStart: "10px" }}>問題を追加</Button>
                    }

                    {props.data.quiz.length > 0 && title !== "" &&
                        <Box style={{ marginBlock: "10px" }}>
                            {isloading ? <CircularProgress /> : <Button variant="contained" onClick={upload} fullWidth style={{ borderRadius: "15px", backgroundColor: '#2B8BA8', boxShadow: "0px 3px 6px #00000029", color: "white", fontSize: "20px" }}>完成</Button>}
                        </Box>}
                </div >
            </div>
        </MuiThemeProvider>

    )
}

let uploadBlob = (url: string, uuid: string, index: number) => {
    return new Promise((res, rej) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            let fileName = Date.now() + index + ".wav"
            storage.ref().child(uuid).child(fileName).put(xhr.response)
                .then(function (snapshot) {
                    res(snapshot.ref.getDownloadURL())
                })
                .catch((e) => {
                    rej(e)
                })
        });
        xhr.send();
    })
}

let uploadImage = async (imageResource: File, uuid: string) => {
    //TODO 拡張子ファイルから取るように変更
    let fileName = Date.now() + ".png"
    let name = await storage.ref().child(uuid).child(fileName).put(imageResource).then((snapshot) => snapshot.ref.getDownloadURL())
    return name
}