import { MuiThemeProvider, CircularProgress } from '@material-ui/core'
import { useEffect, useState } from 'react'
import firebase from '../Firebase'
import { thema } from '../thema';
import axios from 'axios';
import { QuestionColumm } from '../ranking/RankingModule';
import { useParams } from 'react-router-dom';


interface QuizData {
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    title: string,
    userPhoto: string,
    uid: string,
    count: number,
    date: number

}

interface TagsSearchProps {
    tag?: string
}

export let TagsSearch = (props: TagsSearchProps) => {
    const [data, setdata] = useState<QuizData[]>()
    const [loading, setloading] = useState(false)

    const { tag } = useParams<TagsSearchProps>()

    let getData = async () => {
        console.log("search")
        const snapshot = await axios.get<QuizData[]>("https://us-central1-quiz-25aae.cloudfunctions.net/tagSearch-tagSearch", {
            params: {
                tags: tag ?? props.tag
            }
        })
        setdata(snapshot.data)
        setloading(true)
    }
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <MuiThemeProvider theme={thema}>
            {loading &&
                <div style={{ backgroundColor: "#8CB309", margin: "12px", borderRadius: "15px", textAlign: "center", paddingBlock: "1px", marginBlockEnd: "10px" }}>
                    <div style={{ backgroundColor: "#00000045", marginInline: "30px", borderRadius: "15px", marginBlock: "10px", padding: "10px", }}>
                        <div style={{ color: "white", fontSize: "26px", }}>「{tag ?? props.tag}」タグ</div>(10分更新)
                    </div>
                    {data?.map((element, i) => <div key={i}><QuestionColumm date={element.date} goodCount={element.count} uid={element.uid} title={element.title} rankingNumber={i} userPhoto={element.userPhoto}></QuestionColumm></div>)}
                </div>
            }
            {!loading &&
                <div style={{ textAlign: "center" }} >
                    <CircularProgress size={60} />
                </div>
            }
        </MuiThemeProvider >
    )
}