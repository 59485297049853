import { Box, Chip, makeStyles, styled, TextField } from '@material-ui/core';
import * as React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface ChipData {
    key: number;
    label: string;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const useStyles = makeStyles(() => ({
    title: {
        fontSize: "15px",
        color: "white",

    },
    specialOutline: {
        borderColor: "pink",
        borderWidth: 4
    }
}));

type TagInpurProps = {
    tags?: string[];
    isEditor: boolean;
    setTags: (tags: ChipData[]) => void;
}

export const TagInput = (props: TagInpurProps) => {
    const classes = useStyles();
    const history = useHistory()
    const [newTag, setNewTag] = useState<string>("");
    const [isEditMode, setIsEditMode] = useState(false);
    const [chipData, setChipData] = useState<ChipData[]>(
        props.tags?.map((it, i) => {
            return {
                key: i,
                label: it
            }
        }) ?? []);

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const tagSet = () => {
        if (!chipData.find(data => data.label === newTag) && newTag !== "") {
            setChipData([...chipData, { key: chipData.length, label: newTag }]);
            console.log(chipData)
            setNewTag("");
        }
    }

    return (
        <Box style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: 0.5,
            marginInline: "20px",
            marginBlock: "0px"
        }}
            component="ul">
            {
                chipData.map((data) => {
                    let icon;

                    return (
                        <ListItem key={data.key}>
                            <Chip
                                size='small'
                                icon={icon}
                                label={data.label}
                                onDelete={isEditMode ? handleDelete(data) : undefined}
                                onClick={() => {
                                    history.push("/tags/" + data.label)
                                }}
                            />
                        </ListItem>
                    );
                })
            }
            {props.isEditor && isEditMode && (
                <>
                    <Box>
                        <TextField size='small' id="outlined-basic" label="タグの追加" variant="outlined" inputProps={{ className: classes.title }} InputLabelProps={{ className: classes.title }} color="secondary"
                            onKeyPress={e => {
                                if (e.key === "Enter") {
                                    tagSet()
                                }
                            }}
                            onChange={e => {
                                setNewTag(e.target.value);
                            }}
                            value={newTag}
                        />
                    </Box>
                    <ListItem>
                        <Chip
                            icon={<CheckIcon />}
                            size='small'
                            label="編集完了"
                            color="primary"
                            onClick={() => {
                                if (newTag !== "") {
                                    if (!chipData.find(data => data.label === newTag) && newTag !== "") {
                                        setChipData([...chipData, { key: chipData.length, label: newTag }]);
                                        setNewTag("");
                                    }
                                    props.setTags([...chipData, { key: chipData.length, label: newTag }])
                                } else {
                                    props.setTags(chipData)
                                }
                                setIsEditMode(!isEditMode)
                            }}
                        />
                    </ListItem>
                </>
            )
            }
            {props.isEditor && !isEditMode && (
                <Box>
                    <ListItem>
                        <Chip
                            icon={<EditIcon />}
                            size='small'
                            label="タグ編集"
                            color="primary"
                            onClick={() => setIsEditMode(true)}
                        />
                    </ListItem>
                </Box>
            )}
        </Box>

    );
}
