import { LoadingLogin, QuiQuiBar } from "./QuiQuiBar"
import { CreateQuiz } from "./make/CreateQuiz";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from "./Home.tsx";
import { Mypage } from "./mypage/Mypage";
import { createContext, useContext, useState } from "react";
import { OverView } from "./overview/OverView.tsx";
import { QuizUi } from "./answer/QuizUi";
import { ScrollToTop } from "./ScrollToTop";
import { Grid } from "@material-ui/core";
import { Footer } from './footer/Footer';
import { GoodCountPage, RecentlyPage } from "./ranking/GoodCountPage";
import { TagsSearch } from "./tags/TagsSearch";
import { InputSearch } from "./search/InputSearch";


function App() {
  return (
    <ContextProvider>
      <Grid container justify="center">
        <Grid item xs={12} md={6} style={{ backgroundColor: "white" }}>
          <Router>
            <ScrollToTop />
            <div>
              <QuiQuiBar />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/create' component={CreateQuiz} />
                <Route path='/loading' component={LoadingLogin} />
                <Route path='/date' component={RecentlyPage} />
                <Route path='/count' component={GoodCountPage} />
                <Route path='/mypage' component={Mypage} />
                <Route path='/overview/:uid' component={OverView} />
                <Route path='/_overview/:uid' component={OverView} />
                <Route path='/quiz/:uuid/:uid' component={QuizUi} />
                <Route path='/tags/:tag' component={TagsSearch} />
                <Route path='/search' component={InputSearch} />
              </Switch>
            </div>
            <Footer />
          </Router>
        </Grid>
      </Grid>

    </ContextProvider>
  );
}

export default App;

const AppContext = createContext();

export let useAppContext = () => {
  return useContext(AppContext);
}

export let ContextProvider = ({ children }) => {
  const [userName, setuserName] = useState("")
  const [uuid, setuuid] = useState("")
  const [userPhoto, setuserPhoto] = useState("")
  const [isLogin, setIsLogin] = useState(false)

  const value = {
    isLogin,
    setIsLogin,
    userName,
    setuserName,
    uuid,
    setuuid,
    userPhoto,
    setuserPhoto
  };

  return (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
  );
}