import { Grid, Avatar, MuiThemeProvider, Button, CircularProgress, Box } from '@material-ui/core'
import { useEffect, useState } from 'react'
import firebase from '../Firebase'
import { thema } from '../thema';
import { Link } from 'react-router-dom';
import { GoodIndicate } from '../utils/GoodButton';
import { Time } from '../utils/Time';
import axios from 'axios';


interface QuizData {
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    title: string,
    userPhoto: string,
    uid: string,
    count: number,
    date: number

}

type RankingProps = {
    category: string,
    title: string,
    backgroundColor: string,
    size: number
}

export let RankingModules = (props: RankingProps) => {
    const [data, setdata] = useState<QuizData[]>()
    const [loading, setloading] = useState(false)

    let getData = async () => {
        const snapshot = await axios.get<QuizData[]>("https://us-central1-quiz-25aae.cloudfunctions.net/rankingGet-rankingGet", {
            params: {
                category: props.category,
                size: props.size
            }
        })
        setdata(snapshot.data)
        setloading(true)
        setloading(true)
    }
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <MuiThemeProvider theme={thema}>
            {loading &&
                <div style={{ backgroundColor: props.backgroundColor, marginInline: "12px", borderRadius: "15px", textAlign: "center", paddingBlock: "1px", marginBlockEnd: "10px" }}>
                    <div style={{ backgroundColor: "#00000045", marginInline: "30px", borderRadius: "15px", marginBlock: "10px", padding: "10px", }}>
                        <div style={{ color: "white", fontSize: "26px", }}>{props.title}</div>
                        <Button component={Link} to={"/" + props.category} style={{ color: "white", fontSize: "18px", margin: 0, padding: 0 }}>(10分更新)</Button>
                    </div>
                    {data?.map((element, i) => <div key={i}><QuestionColumm date={element.date} goodCount={element.count} uid={element.uid} title={element.title} rankingNumber={i} userPhoto={element.userPhoto}></QuestionColumm></div>)}
                </div>
            }
            {!loading &&
                <div style={{ textAlign: "center" }} >
                    <CircularProgress size={60} />
                </div>
            }
        </MuiThemeProvider >
    )
}

interface QuestionColummProp {
    uid: string,
    title: string,
    rankingNumber: number,
    userPhoto: string,
    goodCount: number,
    date: number
}
export const QuestionColumm = (props: QuestionColummProp) => {

    return (
        <div style={{ marginInline: "20px", marginBlock: "10px" }}>
            <Button fullWidth component={Link} to={"/overview/" + props.uid} style={{ backgroundColor: "#FFFFFF3D", boxShadow: "0px 3px 6px #00000029", borderRadius: "15px", padding: "0px" }}>
                <Grid container alignItems="center" style={{ position: "relative" }}>
                    <Grid item xs={3} style={{ fontSize: "26px", color: "white", borderRight: " solid #00000030", borderWidth: "2px", paddingInlineStart: "3px", marginBlock: 0 }}>
                        {props.rankingNumber + 1}
                        <Box component="div" mt="-15px" style={{ paddingBlock: 0 }}>
                            <GoodIndicate goodCount={props.goodCount} />
                        </Box>
                    </Grid>

                    <Grid item xs={9} style={{ paddingInline: "15px", paddingBlock: "5px" }}>
                        <div style={{ fontSize: "20px", color: "white", marginBlock: "0px", textAlign: "left" }}>
                            {props.title}
                        </div>
                        <div style={{ textAlign: "left" }}>
                            <Time date={new Date(props.date)} />
                        </div>
                        <Avatar src={props.userPhoto} style={{ width: "30px", height: "30px", position: "absolute", bottom: "6px", right: "6px" }} alt="Twitter Avator" />
                    </Grid>
                </Grid>
            </Button>
        </div>
    )
}