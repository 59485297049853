import { Box, Button } from '@material-ui/core';
import { useReactMediaRecorder } from 'react-media-recorder';
import React, { useEffect, useRef, useState } from 'react';
import MicIcon from '@material-ui/icons/Mic';
import { CloudUpload } from '@material-ui/icons';

interface AudioSetProp {
  setBlobUrl: (arg: string) => void
}
let AudioSet = (props: AudioSetProp) => {
  type ModeRec = "None" | "Rec" | "Upload" | "FilePrepared"
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    audio: {
      sampleRate: 16000,
    }
  });
  const [blobUrl, setBlobUrl] = useState(mediaBlobUrl)
  const [mode, setmode] = useState<ModeRec>("None")
  useEffect(() => {
    if (mediaBlobUrl !== null) {
      setBlobUrl(mediaBlobUrl)
      setmode("FilePrepared")
    }
  }, [mediaBlobUrl])
  const fileComplete = () => {
    props.setBlobUrl(blobUrl!!)

  }
  return (
    <div>
      <Button
        style={{ backgroundColor: "#D03333", color: "white", fontSize: "15px" }}
        size="large"
        onClick={() => {
          setmode("Rec")
        }} variant="contained"
        startIcon={<MicIcon />}>Rec</Button>
      <Box component="span" m={"5px"} />
      <Button
        style={{ backgroundColor: "#2689D1", color: "white", fontSize: "15px" }}
        variant="contained"
        color="primary"
        onClick={() => {
          setmode("Upload")
        }}
        size="large"
        startIcon={<CloudUpload />}
      >
        Upload
      </Button>

      {mode === "Rec" && <AudioRec start={startRecording} stop={stopRecording} status={status} />}
      {mode === "Upload" && <AudioUpload setBlob={setBlobUrl} filePrepared={() => { setmode("FilePrepared") }} />}
      {mode === "FilePrepared" && <div><AudioPlay BlobUrl={blobUrl} /> <Button variant="outlined" onClick={fileComplete}>このファイルで決定</Button></div>}
    </div >
  )
}


interface AudioUploadProps {
  setBlob: (arg: string) => void
  filePrepared: () => void
}

const AudioUpload = (props: AudioUploadProps) => {
  const inputRef = useRef(null);

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      props.setBlob(window.URL.createObjectURL(event.target.files[0]))
      props.filePrepared()
    }
  };
  return (
    <div>
      <input
        ref={inputRef}
        type="file"
        accept="audio/wav,audio/webm"
        onChange={onFileInputChange}
      />
    </div>
  );
};

interface AudioRecProps {
  start: () => void
  stop: () => void
  status: string
}

const AudioRec: React.FC<AudioRecProps> = props => {
  const [recordState, setrecordState] = useState(false)
  return (
    <div>
      <p>{props.status}</p>
      {
        recordState ?
          <Button
            style={{ backgroundColor: "#2689D1", color: "white", fontSize: "15px" }}
            onClick={() => {
              props.stop()
              setrecordState(!recordState)
            }
            } >録音終了</Button> :
          <Button
            style={{ backgroundColor: "#D03333", color: "white", fontSize: "15px" }}
            onClick={() => {
              props.start()
              setrecordState(!recordState)
            }} >録音開始</Button>
      }

    </div>
  );
}

function AudioPlay(prop: any) {
  return (
    <div>
      <video src={prop.BlobUrl} controls autoPlay loop />
    </div>
  );
}

export default AudioSet