import { Box } from "@material-ui/core"
import LogoRevert from '../img/logoRevert.png'
import { Twitter } from '@material-ui/icons';

export const Footer = () => {
    return (
        <Box mt="20px" style={{ textAlign: "center", backgroundColor: "#000000A5" }} >
            <img src={LogoRevert} alt="QuiQui" style={{ height: "50px", verticalAlign: "middle" }} />
            <a href="https://twitter.com/quiqui_info" target="_blank" rel="noopener noreferrer" style={{ color: "white", fontSize: "16px", marginLeft: "5px" }}>
                <Twitter style={{ color: "#FFFFFF", verticalAlign: "text-top" }} />
            </a>
            <Box style={{ color: "white", fontSize: "10px" }} mt="-5px" pb="10px">
                @2022 TigerBrown
            </Box>
        </Box>
    )
}