import { Box, Grid } from "@material-ui/core"
import Button from '@material-ui/core/Button';
import StartButton from './image/play-circle.svg'
import StopButton from './image/pause-circle.svg'
import { useState, useEffect } from "react";
import { db, functions } from "../Firebase";
import { useHistory, useParams } from "react-router-dom";
import BackGround from '../img/back.png'
import SwipeableViews from 'react-swipeable-views';
import { CircleChart } from "../overview/CircleChart";
import axios from "axios";
import { TwitterShareButton } from "react-share";
import { Twitter } from "@material-ui/icons";
import { ChipData, TagInput } from "../utils/TagInput";
import { TagsSearch } from "../tags/TagsSearch";
import GoogleAd from "../GoogleAdsense";


let audio: AudioContext;
let source: AudioBufferSourceNode;

type QuizDetailData = {
    answer: string[],
    notAnswer: string[],
    mode: Mode
};

interface AnswerUiProps {
    uid: string,
    uuid: string
}

type QuizDetailDataStore = {
    data: QuizDetailData[],
    title: string,
    userPhoto: string,
    uuid: string
}

export const QuizUi = () => {
    const { uid } = useParams<AnswerUiProps>()
    const { uuid } = useParams<AnswerUiProps>()
    const [description, setDescription] = useState<QuizDetailData[]>();
    const [loading, setloading] = useState(false)
    const [title, settitle] = useState("")
    // const colorTheme = ['#0EB49A', '#0EB461', '#54B40E', '#B49A0E', '#9AB40E', '#0E6EB4', '#1B0EB4', '#4E0EB4', '#B40E67', '#B40E0E']
    useEffect(() => {
        getQuestion()
        // eslint-disable-next-line 
    }, [])
    let getQuestion = async () => {
        const question = await axios.get<QuizDetailDataStore>("https://us-central1-quiz-25aae.cloudfunctions.net/rankingGet-quizGet", {
            params: {
                uid: uid,
                uuid: uuid
            }
        })
        settitle(question.data.title)
        setDescription(question.data.data)
        setloading(true)
    }

    return (
        <div>
            {loading && <UiCompornent title={title} question={description!!} />}
        </div>
    )

}

interface QuizObject {
    value: string,
    isAnswer: boolean,
    answerMode: AnswerStateType
}

type UiCompornentProps = {
    question: QuizDetailData[]
    title: string
}

const UiCompornent = (props: UiCompornentProps) => {
    const [state, setState] = useState(0)
    const [isAnswer, setIsAnswer] = useState<Boolean[]>(props.question.map(() => { return false }))
    const [result, setresult] = useState(false)

    let updateAnswer = (bool: Boolean, i: number) => {
        let buff = [...isAnswer]
        buff[i] = bool
        setIsAnswer(buff)
    }

    return (
        <Box mt="10px">
            <Grid container justify="center" alignContent="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <SwipeableViews
                        onTransitionEnd={() => {
                            window.scrollTo(0, 0)
                        }}
                        onSwitching={(i, t) => {
                            setState(i)
                        }}
                        index={state}
                        enableMouseEvents>
                        {
                            props.question.map((element: QuizDetailData, i: number) => {
                                let answerObject: QuizObject[] = element.answer.map((it: string) => {
                                    return {
                                        value: it,
                                        isAnswer: true,
                                        answerMode: "yet"
                                    }
                                })
                                let notAnswerObject: QuizObject[] = element.notAnswer.map((it: string) => {
                                    return {
                                        value: it,
                                        isAnswer: false, answerMode: "yet"
                                    }
                                })
                                let quizMode = element.mode

                                return <Card key={i}
                                    mode={quizMode}
                                    question={element}
                                    answerCorrect={answerObject}
                                    answerNotCorrect={notAnswerObject}
                                    currentNumber={i + 1}
                                    endNumber={props.question.length}
                                    isResultMode={result}
                                    clickAnswer={() => {
                                        setState(i + 1)
                                        updateAnswer(true, i)
                                    }}
                                    clickNotAnswer={() => {
                                        setState(i + 1)
                                        updateAnswer(false, i)
                                    }} />
                            })
                        }
                        <EndPage answerList={isAnswer}
                            checkResult={() => {
                                setState(0)
                            }}
                            resultMode={() => {
                                setresult(true)
                            }}
                            title={props.title} />
                    </SwipeableViews>

                </Grid>

            </Grid>
        </Box >
    )
}
type Mode = "audio" | "image" | "text"

interface CardProps {
    mode: Mode,
    question: any,
    answerCorrect: QuizObject[],
    answerNotCorrect: QuizObject[],
    currentNumber: number,
    endNumber: number,
    isResultMode: boolean,
    clickAnswer: () => void,
    clickNotAnswer: () => void,
}

type AnswerStateType = "yet" | "answerd"

const Card = (props: CardProps) => {
    const [AnswerMode, setAnswerMode]
        = useState<AnswerStateType[]>(props.answerCorrect.concat(props.answerNotCorrect).map((_) => { return "yet" }))
    let commonOnClick = (i: number) => {
        let buff: AnswerStateType[] = [...AnswerMode].map((it) => { return it = "yet" })
        buff[i] = "answerd"
        setAnswerMode(buff)
    }

    const [resultMode, setresultMode] = useState(props.isResultMode)

    const [AnswerAll, setAnswerAll] = useState(props.answerCorrect.concat(props.answerNotCorrect))

    useEffect(() => {
        setresultMode(props.isResultMode)
    }, [props.isResultMode])

    useEffect(() => {
        let a = AnswerAll.length;
        let buff = [...AnswerAll]
        while (a) {
            let j = Math.floor(Math.random() * a);
            let t = buff[--a];
            buff[a] = buff[j];
            buff[j] = t;
        }
        setAnswerAll(buff)
        // 一回のみの実行のためlintをdisable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <div id="box" style={{ marginInline: "5vw", paddingTop: "15px", paddingBottom: "10px", backgroundColor: "#0EB49A", borderRadius: "15px", textAlign: "center" }}>
                <div style={{ borderStyle: "none", paddingInline: "5px", backgroundColor: "#0000002F", borderRadius: "12px", textAlign: "center", marginInline: "20px", }}>
                    <AnswerNumber currentNumber={props.currentNumber} endNumber={props.endNumber} />
                </div>
                <div style={{ backgroundImage: `url(${BackGround})`, backgroundSize: "cover", marginInline: "20px", marginBlock: "10px", paddingBottom: "30px", borderRadius: "14px" }}>
                    <Box component="div" style={{ font: "normal normal normal 2rem Hiragino Kaku Gothic ProN" }} pt="30px" pr="15px" pl="15px">{props.question.question}</Box>
                    {props.mode === "audio" && <Audio audioFileName={props.question.fileName} />}
                    {props.mode === "image" && <Image imageUrl={props.question.fileName} />}
                </div>
                {
                    AnswerAll.map((element: QuizObject, i: number): JSX.Element => {
                        return element.isAnswer ?
                            <AnswerButton name={element.value} key={element.value} onClick={() => {
                                props.clickAnswer()
                                commonOnClick(i)
                            }} answerState={AnswerMode[i]} resultMode={resultMode} /> :
                            <NotAnswerButton name={element.value} key={element.value} onClick={() => {
                                props.clickNotAnswer()
                                commonOnClick(i)
                            }} answerState={AnswerMode[i]} resultMode={resultMode} />
                    })
                }
            </div>

        </div >
    )
}

interface EndPageProps {
    answerList: Boolean[]
    checkResult: () => void
    resultMode: () => void
    title: string
}

const EndPage = (props: EndPageProps) => {
    const { uid } = useParams<AnswerUiProps>()
    const history = useHistory()
    const [tags, setTags] = useState<string[]>([])
    const [hashtags, sethashtags] = useState<string[]>([])
    useEffect(() => {
        let hashtags = [...tags] ?? []
        hashtags.push("QuiQui")
        sethashtags(hashtags)
    }, [tags])
    useEffect(() => {
        let getTags = async () => {
            let snapshot = await db.collection("answer").doc(uid).get()
            if (snapshot.exists) {
                setTags(snapshot.data()?.tags ?? [])
            }
        }
        getTags()
    }, [])
    const [resultMode, setresultMode] = useState(false)
    const sendAnswerData = () => {
        let correct = props.answerList.filter((it) => it === true).length
        let incorrect = props.answerList.length - correct
        functions.httpsCallable("answerLogic-answer")({ uid: uid, incorrect: incorrect }).then(() => {
        }).catch(e => {
            // console.log(e);
        })
    }
    const correctMsg = props.answerList.length + "問中" + props.answerList.filter((it) => it === true).length + "問 正解！"
    return (
        <div>
            <div id="box" style={{ marginInline: "5vw", paddingTop: "15px", paddingBottom: "10px", backgroundColor: "#0EB49A", borderRadius: "15px", textAlign: "center" }}>
                <Box style={{ marginInline: "20px", font: "normal normal normal 30px/50px Heisei Maru Gothic Std" }}>
                    <div style={{ borderStyle: "none", paddingInline: "5px", backgroundColor: "#0000002F", borderRadius: "12px", textAlign: "center", color: "white" }}>
                        {resultMode ? <span>{correctMsg}</span> : "最終確認画面"}
                    </div>
                    {
                        resultMode ? <div>


                            <div style={{ font: "normal normal normal 30px/50px Heisei Maru Gothic Std", color: "#ffffff", padding: 0, backgroundColor: "#0000002F" }}>

                            </div>
                            <Button style={{ fontSize: "20px", marginBlock: "10px", color: "white", padding: "10px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }} onClick={() => { props.checkResult() }} fullWidth　>回答を見る</Button>
                            <Box style={{
                                backgroundImage: `url(${BackGround})`, backgroundSize: "cover",
                                backgroundColor: "white", marginInline: "20px", paddingInline: "30px", paddingBlock: "5px", borderRadius: "10px"
                            }}>
                                <CircleChart data={[{ title: "正解", value: props.answerList.filter((it) => it === true).length }, { title: "不正解", value: props.answerList.length - props.answerList.filter((it) => it === true).length }]} />
                            </Box>
                            <TwitterShareButton style={{ width: "100%", fontSize: "20px", marginBlock: "10px", color: "white", padding: "5px", backgroundColor: "#1DA1F2", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }} url={"https://quiqui.tb-works.com/overview/" + uid} title={correctMsg + props.title} hashtags={hashtags}
                                related={["quiqui_info"]} via={"みんなで作るクイズアプリQuiQui"}>
                                <span style={{ marginInline: "10px" }}>
                                    Twitterで共有
                                    <Twitter style={{ paddingInlineStart: "10px", verticalAlign: "middle" }} />
                                </span>

                            </TwitterShareButton>
                            <Button style={{ fontSize: "20px", marginBlock: "10px", color: "white", padding: "10px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }} onClick={() => { history.push("/overview/" + uid) }} fullWidth>クイズ情報に戻る</Button>
                        </div> :
                            <Button fullWidth style={{ fontSize: "20px", marginBlock: "10px", color: "white", padding: "10px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }} onClick={() => {
                                setresultMode(true)
                                props.resultMode()
                                sendAnswerData()
                            }}>回答完了して結果を見る</Button>
                    }
                </Box>
                <GoogleAd />

            </div>
            {
                resultMode && tags.map((it, i) => {
                    console.log(tags)
                    return <TagsSearch tag={it} key={i} />
                })


            }

        </div>
    )
}

interface ImageProps {
    imageUrl: string
}
export const Image = (props: ImageProps) => {
    return (
        <Box mt="20px">
            <img src={props.imageUrl} width="250px" alt="クイズ画像" />
        </Box>
    )
}

interface AudioProps {
    audioFileName: string
}
export const Audio = (props: AudioProps) => {
    const [startButton, setStartButton] = useState(1);

    return (
        <div>
            {startButton === 0 && <div style={{ marginTop: "15px", height: "15vh" }}> <LoadAnimation /></div>}
            {startButton === 1 && <Button onClick={function () {
                audio = new AudioContext()
                audio.createBufferSource().start(0);
                PlayAudio(1)
            }}><img src={StartButton} alt="startbutton" style={{ height: "15vh" }}></img></Button>}
            {startButton === 2 && <Button onClick={function () { PlayAudio(0) }}><img src={StopButton} alt="stopbutton" style={{ height: "15vh" }}></img></Button>}

        </div >
    )

    function PlayAudio(mode: number) {
        if (mode === 1) {
            setStartButton(0);
            let url = props.audioFileName
            let request = new XMLHttpRequest();
            request.responseType = 'blob';
            request.onload = function (event) {
                setStartButton(2);
                request.response.arrayBuffer().then((arrayBuffer: any) => {
                    audio.decodeAudioData(
                        arrayBuffer,
                        (audioBuffer: any) => {
                            source = audio.createBufferSource();
                            source.buffer = audioBuffer;
                            source.connect(audio.destination);
                            source.start(0);
                            source.onended = () => {
                                setStartButton(1);
                            }
                        });

                }, (error: any) => {
                    console.error(error);
                }
                );
            };
            request.open("GET", url);

            request.send();
        } else if (mode === 0) {
            source.stop();
        }

    }

}

const LoadAnimation = () => {
    return (
        < div >
            < div className="loader loader--style8" title="7" >
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width="48px" height="60px" viewBox="0 0 24 30"  >
                    <rect x="0" y="10" width="4" height="10" fill="#0EB49A" opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="8" y="10" width="4" height="10" fill="#0EB49A" opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="16" y="10" width="4" height="10" fill="#0EB49A" opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                </svg>
            </div >
        </div >
    )

}

interface AnswerButtonPropsInterface {
    onClick: () => void,
    name: string,
    answerState: AnswerStateType,
    resultMode: Boolean
}

const NotAnswerButton = (props: AnswerButtonPropsInterface) => {
    let color = "#ffffff";
    switch (props.answerState) {
        case "yet":
            color = "#ffffff"
            break;
        case "answerd":
            color = "#000000"
            break;
    }
    let onClick = () => {
        props.onClick()

    }
    return (
        <div style={{ marginInline: "20px", marginBlock: "15px" }}>
            <Button
                disabled={props.resultMode === true}
                style={{
                    backgroundColor: "#FFFFFF5D", borderRadius: "12px",
                    font: "normal normal bold 25px/40px Arial"
                }}
                onClick={() => { onClick() }} fullWidth><span style={{ color: color }}>{props.name}</span> <span style={{ color: "#0000ff" }}>{props.resultMode && "×"}</span>
            </Button>
        </div >
    )
}

const AnswerButton = (props: AnswerButtonPropsInterface) => {
    let color = "#ffffff";
    switch (props.answerState) {
        case "yet":
            color = "#ffffff"
            break;
        case "answerd":
            color = "#000000"
            break;
    }
    let onClick = () => {
        props.onClick()

    }
    return (
        <div style={{ marginInline: "20px", marginBlock: "15px" }}>
            <Button
                disabled={props.resultMode === true}
                style={{
                    backgroundColor: "#FFFFFF5D", borderRadius: "12px",
                    font: "normal normal bold 25px/40px Arial"
                }}
                onClick={() => { onClick() }} fullWidth><span style={{ color: color }}>{props.name}</span> <span style={{ color: "#ff0000" }}>{props.resultMode && "◯"}</span>
            </Button>
        </div >
    )
}

interface AnswerNumberProps {
    currentNumber: number,
    endNumber: number
}
const AnswerNumber = (props: AnswerNumberProps) => {
    return (
        <div>
            <div style={{ font: "normal normal normal 30px/50px Heisei Maru Gothic Std", color: "#ffffff", padding: 0 }}>
                <span>
                    第{props.currentNumber}問</span><span style={{ fontSize: "70%" }}> /{props.endNumber}問</span></div>
        </div>
    )
}
