import { Link } from 'react-router-dom'
import { RankingModules } from './ranking/RankingModule'
import { Box, Button, CircularProgress, MuiThemeProvider } from '@material-ui/core'
import { thema } from './thema';
import Logo from './img/logo.png'
import { useState } from 'react';
import { useAppContext } from './App';
import { auth, provider } from './Firebase';

export const Home = () => {
    return (
        <div>
            <FirstComponent />
            <RankingModules category='count' title='いいね！ランキング' backgroundColor='#2B8BA8' size={5} />
            <RankingModules category='date' title='最近追加されたクイズ' backgroundColor='#A85F2B' size={5} />
        </div>
    )
}

export const FirstComponent = () => {
    const { isLogin, setIsLogin } = useAppContext()
    const [isLoading, setisLoading] = useState(true)
    let login = () => {
        auth.signInWithRedirect(provider);
    }
    auth.onAuthStateChanged(function (user) {
        setisLoading(false)
        if (user) {
            setIsLogin(true)
        } else {
            setIsLogin(false)
        }
    });
    return (
        <MuiThemeProvider theme={thema}>
            <div style={{ backgroundColor: thema.palette.primary.main, margin: "12px", borderRadius: "15px", textAlign: "center", padding: "5px" }}>
                <Box component="div" pb="10px" style={{ backgroundColor: 'white', margin: "12px", textAlign: "center", borderRadius: "15px", color: "#9B9B9B", paddingInline: "40px", fontSize: "16px", letterSpacing: "0px" }}>
                    <Box component="div" mb="-10px"><img src={Logo} alt="QuiQui" style={{ height: "70px" }}></img></Box>
                    みんなで盛り上がる<br />Twitter連携クイズアプリ<br />音・画像当てクイズも作れます
                </Box>
                <div style={{ margin: "12px", borderRadius: "15px", textAlign: "center" }}>

                    {isLoading && <CircularProgress color='secondary' />}
                    {
                        !isLoading && (!isLogin ?
                            <Button fullWidth onClick={login} style={{ borderRadius: "15px", backgroundColor: '#FFFFFF3D', boxShadow: "0px 3px 6px #00000029", }}>
                                <div>
                                    <div style={{ color: "white", fontSize: "28px" }}>
                                        クイズを作成する
                                    </div>
                                    <Box style={{ color: "#FFFFFF9B", fontSize: "20px" }} mt="-15px">
                                        (要ログイン)
                                    </Box>
                                </div>
                            </Button>

                            : <Button fullWidth component={Link} to="/create" style={{ borderRadius: "15px", backgroundColor: '#FFFFFF3D', boxShadow: "0px 3px 6px #00000029", }}>
                                <div>
                                    <div style={{ color: "white", fontSize: "28px" }}>
                                        クイズを作成する
                                    </div>
                                </div>
                            </Button>)
                    }
                </div>
            </div>
        </MuiThemeProvider>
    )
}