import { Box, Button, Chip } from "@material-ui/core"
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import { useState } from "react";
import { functions } from "../Firebase";
interface GoodButtonProps {
    uid: string,
    count: number
}
export const GoodButton = (props: GoodButtonProps) => {
    const [count, setcount] = useState(props.count)
    let goodButton = () => {
        if (count === props.count) {
            setcount(count + 1)
            functions.httpsCallable("answerLogic-good")({ uid: props.uid }).then(() => {
                console.log("count up")
            }).catch(e => {
                console.log(e);
            })
        }
    }
    return (
        <Button onClick={goodButton} fullWidth style={{ fontSize: "25px", color: "white", padding: "0px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }}>
            <Box component="span" mr="10px">いいね</Box>
            <GoodIndicate goodCount={count} />
        </Button>
    )
}

interface GoodIndicateProps {
    goodCount: number
}
export const GoodIndicate = (props: GoodIndicateProps) => {
    return (
        <Chip icon={<ThumbUpOutlinedIcon />} label={props.goodCount.toString()} color="primary" size="small" style={{ backgroundColor: "#0000004B", paddingInlineStart: "6px" }}></Chip>
    )
}