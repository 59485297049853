import { MuiThemeProvider, CircularProgress, TextField, Button } from '@material-ui/core'
import { useEffect, useState } from 'react'
import firebase from '../Firebase'
import { thema } from '../thema';
import axios from 'axios';
import { QuestionColumm } from '../ranking/RankingModule';
import { useParams } from 'react-router-dom';


interface QuizData {
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    title: string,
    userPhoto: string,
    uid: string,
    count: number,
    date: number

}
export let InputSearch = () => {
    const [data, setdata] = useState<QuizData[]>()
    const [loading, setloading] = useState(true)
    const [query, setquery] = useState("")

    let getData = async () => {
        console.log("search")
        const snapshot = await axios.get<QuizData[]>("https://us-central1-quiz-25aae.cloudfunctions.net/tagSearch-tagSearch", {
            params: {
                tags: query
            }
        })
        setdata(snapshot.data)
        setloading(true)
    }
    return (
        <MuiThemeProvider theme={thema}>
            <TextField label="検索ワード" variant="outlined" onChange={(e) => {
                setquery(e.target.value)
            }} value={query} />
            <Button onClick={() => { getData() }} >検索</Button>
            {loading &&
                <div style={{ backgroundColor: "#0EB49A", margin: "12px", borderRadius: "15px", textAlign: "center", paddingBlock: "1px", marginBlockEnd: "10px" }}>
                    {data?.map((element, i) => <div key={i}><QuestionColumm date={element.date} goodCount={element.count} uid={element.uid} title={element.title} rankingNumber={i} userPhoto={element.userPhoto}></QuestionColumm></div>)}
                </div>
            }
            {!loading &&
                <div style={{ textAlign: "center" }} >
                    <CircularProgress size={60} />
                </div>
            }
        </MuiThemeProvider >
    )
}