import { Box, Button, FormControl, MenuItem, MuiThemeProvider, Select, TextField } from "@material-ui/core"
import { useState } from "react"
import { Confirm } from "./Confirm"
import AudioSet from "./AudioSet"
import { ImageMode } from "./ImageMode"
import { EnterChoseBoxForm } from "./AnswerForm"
import { thema } from "../thema"
import { AddCircle } from "@material-ui/icons"
import BackGround from '../img/back.png'
import { useEffect } from "react"


export interface QuizDataStore {
    quiz: QuizForm[]
}
export interface QuizForm {
    answer: string[]
    notAnswer: string[]
    question?: string
    imageResource?: File
    audioBlobUrl?: string
    mode?: Mode
}
type Mode = "audio" | "image" | "text"

export const CreateQuiz = () => {
    type modeType = "init" | "addEdit" | "confirm" | "afterEdit"
    const [mode, setmode] = useState<modeType>("init")
    const [quizData, setquizData] = useState<QuizDataStore>({ quiz: [] })
    const [afterEditModeNumber, setAfterEditModeNumber] = useState<number>(0)
    const [title, settitle] = useState("")
    const [nowChoseNumber, setnowChoseNumber] = useState<number>(0)
    let setQuiz = (arg: QuizForm) => {
        let newState = [...quizData.quiz, arg]
        setnowChoseNumber(newState.length - 1)
        setquizData({ quiz: newState })
        setmode("confirm")
    }
    let prevButton = () => {
        setmode("confirm")
    }
    let afterEditQuiz = (arg: QuizForm) => {
        let newState = [...quizData.quiz]
        newState[afterEditModeNumber] = arg
        setnowChoseNumber(afterEditModeNumber)
        setquizData({ quiz: newState })
        setmode("confirm")
    }
    useEffect(() => {
        window.history.pushState(null, "state", window.location.href);
        window.addEventListener('popstate', onUnBack);
        window.addEventListener('beforeunload', onUnload);

        return () => {
            window.removeEventListener('beforeunload', onUnload);
            window.removeEventListener('popstate', onUnBack);
        }
    })

    const onUnBack = (e: any) => {
        alert('データが消えてしまうため、ブラウザバックを使わないでください。');
        window.history.go(1);
    }

    const onUnload = (e: { preventDefault: () => void; returnValue: string; }) => {
        e.preventDefault();
        e.returnValue = '';
    }

    return (
        <div>
            {mode === "init" && <Confirm defaultIndex={0} data={quizData} changeAddEditMode={() => { setmode("addEdit") }} changeAfterEditMode={(args: number) => { setmode("afterEdit"); setAfterEditModeNumber(args) }} changeTitle={(title: string) => { settitle(title) }} title={title} />}
            {mode === "addEdit" && <CreateQuizComponent prevButton={prevButton} setQuizData={setQuiz} defaultData={{ answer: [""], notAnswer: ["", "", ""] }} />}
            {mode === "confirm" && <Confirm defaultIndex={nowChoseNumber} data={quizData} changeAddEditMode={() => { setmode("addEdit") }} changeAfterEditMode={(args: number) => { setmode("afterEdit"); setAfterEditModeNumber(args) }} changeTitle={(title: string) => { settitle(title) }} title={title} />}
            {mode === "afterEdit" && <CreateQuizComponent prevButton={prevButton} setQuizData={afterEditQuiz} defaultData={quizData.quiz[afterEditModeNumber]} />}

        </div>
    )
}

// interface MakeTitleProps {
//     setTitle: (title: string) => void
// }

// export const MakeTitle = () => {
//     let titleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         settitle(e.target.value)
//     }
//     const changeCreate = () => {

//     }
//     return (
//         <Box>
//             <TextField label="クイズタイトル" onChange={titleChange} />
//             <Button onClick={changeCreate} variant="text" color="default" >クイズを作成</Button>
//         </Box>
//     )
// }

interface CreateQuizComponentProps {
    defaultData: QuizForm,
    setQuizData: (arg: QuizForm) => void,
    prevButton: () => void
}
let CreateQuizComponent = (props: CreateQuizComponentProps) => {
    const [quiz, setQuiz] = useState<QuizForm>({
        answer: props.defaultData.answer,
        notAnswer: props.defaultData.notAnswer
    })
    const [choseMode, setchoseMode] = useState<Mode>(
        props.defaultData.mode ?? "text"
    )
    const [question, setquestion] = useState<string>(
        props.defaultData.question ?? ""
    )
    const [imageResource, setImageResource] = useState<File | undefined>(
        props.defaultData.imageResource
    )
    type AnswerMode = "one" | "multiple"
    const [choseAnswerMode, setchoseAnswerMode] = useState<AnswerMode>(
        props.defaultData.answer.length > 1 ? "multiple" : "one"
    )
    const [BlobUrl, setBlobUrl] = useState(
        props.defaultData.audioBlobUrl ?? ""
    )
    let setAnswer = (arg: string, index: number, isAnswer: boolean) => {
        let bufState = isAnswer ? [...quiz.answer] : [...quiz.notAnswer]
        bufState[index] = arg
        isAnswer ? setQuiz({ answer: bufState, notAnswer: quiz.notAnswer }) : setQuiz({ answer: quiz.answer, notAnswer: bufState })
    }

    let setDisable = (index: number, isAnswer: boolean) => {
        let bufState = isAnswer ? [...quiz.answer] : [...quiz.notAnswer]
        if (bufState.length > 1) {
            bufState.splice(index, 1)
        } else {
            bufState[index] = ""
        }
        isAnswer ? setQuiz({ answer: bufState, notAnswer: quiz.notAnswer }) : setQuiz({ answer: quiz.answer, notAnswer: bufState })
    }
    let setEnable = (isAnswer: boolean) => {
        let bufState = isAnswer ? [...quiz.answer] : [...quiz.notAnswer]
        bufState.push("")
        isAnswer ? setQuiz({ answer: bufState, notAnswer: quiz.notAnswer }) : setQuiz({ answer: quiz.answer, notAnswer: bufState })
    }
    let changeSelect = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        setImageResource(undefined)
        setBlobUrl("")
        setchoseMode(event.target.value as Mode)
    }
    let changeAnswerModeSelect = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        //単数化
        setchoseAnswerMode(event.target.value as AnswerMode)
    }
    let questionOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setquestion(e.target.value)
    }
    let onSave = () => {
        let newState = quiz
        newState.question = question
        switch (choseMode) {
            case "audio":
                newState.audioBlobUrl = BlobUrl
                break
            case "image":
                newState.imageResource = imageResource
                break
        }
        newState.mode = choseMode
        props.setQuizData(newState)
    }
    let onPrev = () => {
        props.prevButton()
    }
    let canSend = () => {
        let tempBoolean: boolean = (BlobUrl !== "") || (imageResource !== undefined) || (choseMode === "text")
        return !tempBoolean || (question === "") || (quiz.answer.filter((it) => it === "").length > 0) || (quiz.notAnswer.filter((it) => it === "").length > 0)
    }
    return (
        <MuiThemeProvider theme={thema}>
            <div style={{
                backgroundColor: thema.palette.primary.main,
                marginInline: "12px", marginTop: "10px", borderRadius: "10px", textAlign: "center", paddingTop: "5px", paddingBottom: "10px"
            }}>
                <Box style={{ color: "white", fontSize: "35px", background: "#0000002E", borderRadius: "10px", marginInline: "20px", marginBlock: "10px" }}>
                    第1問
                </Box>
                <Box style={{ marginInline: "20px", borderRadius: "10px" }}>
                    <FormControl fullWidth variant="standard" style={{ backgroundColor: thema.palette.secondary.main, borderRadius: "10px" }}>
                        <Select disableUnderline value={choseMode} onChange={changeSelect} style={{ color: "white", fontSize: "20px" }}>
                            <MenuItem value={"text"}>テキスト問題</MenuItem>
                            <MenuItem value={"audio"}>音声問題</MenuItem>
                            <MenuItem value={"image"}>画像問題</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box style={{ backgroundImage: `url(${BackGround})`, backgroundSize: "cover", fontSize: "35px", paddingBlock: "10px", borderRadius: "10px", paddingInline: "20px", marginInline: "20px", marginBlock: "10px" }}>
                    {choseMode === "audio" && (BlobUrl === "" ? <AudioSet setBlobUrl={setBlobUrl} /> : <AudioPlayMode blobUrl={BlobUrl} />)}
                    {choseMode === "image" && <ImageMode setImageResource={setImageResource} defaultImage={imageResource} />}
                    <TextField label="問題を入力" variant="outlined" style={{ marginTop: "10px" }}
                        color="secondary" onChange={questionOnchange} defaultValue={props.defaultData.question} fullWidth />
                </Box>

                <form noValidate autoComplete="off" style={{ marginInline: "20px", borderRadius: "10px" }}>
                    <FormControl fullWidth style={{ backgroundColor: "#367483", borderRadius: "10px" }} >
                        <Select disableUnderline value={choseAnswerMode} onChange={changeAnswerModeSelect} style={{ color: "white", fontSize: "20px" }}>
                            <MenuItem value={"one"}>一答クイズ</MenuItem>
                            {/* <MenuItem value={"multiple"}>複数選択クイズ</MenuItem> */}
                        </Select>
                    </FormControl>
                    {quiz.answer.map((it: string, index: number) => {
                        return <div key={index} >
                            {<EnterChoseBoxForm label={"正答" + (index + 1)} answer={it} setAnswer={(arg: string) => { setAnswer(arg, index, true) }} setDisable={() => { setDisable(index, true) }} />}
                        </div>
                    })}
                    {choseAnswerMode === "multiple" && quiz.answer.length < 3 && <Button onClick={() => { setEnable(true) }} fullWidth style={{ backgroundColor: "#FFFFFFED", marginBlockEnd: "10px", borderRadius: "10px" }}><AddCircle /></Button>
                    }
                    {quiz.notAnswer.map((it: string, index: number) => {
                        return <div key={"notAnswer" + index} >
                            {<EnterChoseBoxForm label={"誤答" + (index + 1)} answer={it} setAnswer={(arg: string) => { setAnswer(arg, index, false) }} setDisable={() => { setDisable(index, false) }} />}
                        </div>
                    })}
                    {quiz.notAnswer.length < 6 && <Button onClick={() => { setEnable(false) }} fullWidth style={{ backgroundColor: "#FFFFFFED", marginBlockEnd: "10px", borderRadius: "10px" }}><AddCircle /></Button>}

                    {canSend() ? <Button disabled fullWidth style={{ borderRadius: "15px", backgroundColor: '#FFFFFF7D', color: "white", fontSize: "18px", marginBlockEnd: "10px" }}>保存</Button> : <Button onClick={onSave} fullWidth style={{
                        borderRadius: "15px", backgroundColor: '#A85F2B', boxShadow: "0px 3px 6px #00000029", color: "white", fontSize: "18px", marginBlockEnd: "10px"
                    }}>保存</Button>}

                    <Button onClick={onPrev} fullWidth style={{ borderRadius: "15px", backgroundColor: '#FFFFFF3D', boxShadow: "0px 3px 6px #00000029", color: "white", fontSize: "18px" }}>前の画面に戻る</Button>
                </form>

            </div>
        </MuiThemeProvider >
    )
}
interface AudioPlayModeProps {
    blobUrl: string
}
let AudioPlayMode = (prop: AudioPlayModeProps) => {
    return (
        //ここで再生
        <div>
            <video src={prop.blobUrl} controls autoPlay loop />
            <Button>変更する</Button>
        </div>
    )
}