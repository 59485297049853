import { AppBar, Avatar, Box, Button, CircularProgress, Menu, MenuItem, Toolbar } from "@material-ui/core";
import { useState } from "react";
import { auth, provider } from "./Firebase";
import Logo from './img/logo.png'
import { Link, useHistory } from 'react-router-dom'
import { useAppContext } from "./App";

export let QuiQuiBar = () => {
  return (
    <Box>
      <AppBar position="relative" color="inherit">
        <Toolbar style={{ paddingRight: 0 }}>
          <div style={{ flexGrow: 1 }}>
            <Link to="/">
              <img src={Logo} alt="QuiQui" style={{ height: "50px" }}></img>
            </Link>
          </div>
          <Auth />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

let Auth = () => {
  const [isLoading, setisLoading] = useState(true)
  const { isLogin, setIsLogin, userName, setuserName, setuuid, setuserPhoto, userPhoto } = useAppContext()
  auth.onAuthStateChanged(function (user) {
    setisLoading(false)
    if (user) {
      setIsLogin(true)
      setuuid(user.uid)
      setuserName(user.displayName ? user.displayName : "Error")
      setuserPhoto(user.photoURL ? user.photoURL.replace("_normal", "") : "")
    } else {
      setIsLogin(false)
    }
  });

  return (
    <Box component="div" style={{ flexGrow: 0 }} >
      {isLoading ? <CircularProgress /> : !isLogin ?
        <Login /> : <UserIcon userName={userName} userPhotoUrl={userPhoto} />}
    </Box>
  );
}

let Login = () => {
  let login = () => {
    auth.signInWithRedirect(provider);
    auth.getRedirectResult().then(result => {
    }).catch(error => {
      console.log(error);
    });
  }
  return (
    <Box pr="10px">      <Button onClick={login} color="primary" variant="contained" style={{ textAlign: "right" }}>Login</Button>
    </Box>)
}

interface UserIconProps {
  userName: string
  userPhotoUrl: string
}
let UserIcon: React.FC<UserIconProps> = (props) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let logout = () => {
    auth.signOut()
  }
  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
        <Box pr="10px">
          <Avatar src={props.userPhotoUrl} alt="Twitter Avator" />
        </Box>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => {
          handleClose()
          history.push("/mypage")
        }}>マイページ</MenuItem>
        <MenuItem onClick={() => {
          handleClose()
          history.push("/create")
        }}>クイズを作成</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>

  )
}

export const LoadingLogin = () => {
  return (
    <div>
      <CircularProgress />
    </div>
  )
}