import { useEffect, useState } from "react"
import { useAppContext } from "../App"
import { db } from "../Firebase"
import firebase from 'firebase'
import { Button, CircularProgress, Avatar, MuiThemeProvider, Box, Grid } from "@material-ui/core"
import { thema } from "../thema"
import { Link } from "react-router-dom"
import { Time } from "../utils/Time"
import BackGround from '../img/back.png'



export let Mypage = () => {
    const { uuid, userName, userPhoto } = useAppContext()

    return (
        <Box mt="10px">
            <MuiThemeProvider theme={thema}>
                <Avatar src={userPhoto} alt="Twitter Avator" style={{ width: "80px", height: "80px", margin: "0 Auto", boxShadow: "0px 3px 6px #00000029" }} />
                <Box style={{
                    backgroundImage: `url(${BackGround})`, backgroundSize: "cover",
                    backgroundColor: "white", marginInline: "20px", paddingInline: "30px", paddingBlock: "5px", paddingBottom: "10px", borderRadius: "10px", paddingTop: "30px"
                }} mt="-40px">
                    <Box style={{ padding: "5px", fontSize: "40px", textAlign: "center" }} mt="10px">{userName}</Box>
                    <Grid container justify="center" alignItems="center">
                        {/* <Grid item xs={4}>
                            <Box style={{ fontSize: "45px", color: thema.palette.secondary.main, textAlign: "center" }}>
                                10
                                <Box style={{ fontSize: "15px", color: thema.palette.secondary.main }} mt="-8px">
                                    作成
                                </Box>
                            </Box>
                        </Grid> */}
                        {/* TODO 回答数を管理するようになったら使用
                        <Grid item xs={4}>
                            <Box style={{ fontSize: "45px", color: thema.palette.primary.main, textAlign: "center" }}>
                                10
                                <Box style={{ fontSize: "15px", color: thema.palette.primary.main }} mt="-10px">
                                    回答
                                </Box>
                            </Box>
                        </Grid> */}

                        {/* <Grid item xs={4}>
                            <Box style={{ fontSize: "45px", color: thema.palette.info.main, textAlign: "center" }}>
                                20
                                <Box style={{ fontSize: "15px", color: thema.palette.info.main }} mt="-10px">
                                    いいね
                                </Box>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>

                <CreateQuiz />

                {uuid ? <MyCreateData uuid={uuid} /> : <Box width="100%" textAlign="center"><CircularProgress /></Box>}

            </MuiThemeProvider >
        </Box>
    )
}

const CreateQuiz = () => {
    return (
        <MuiThemeProvider theme={thema}>
            <Box style={{ margin: "12px", borderRadius: "10px", paddingBlock: "15px", paddingInline: "20px", textAlign: "center", backgroundColor: thema.palette.primary.main }}>
                <Button fullWidth component={Link} to="/create" style={{ borderRadius: "10px", backgroundColor: "#FFFFFF3D", boxShadow: "0px 3px 6px #00000029", }}>
                    <div>
                        <div style={{ color: "white", fontSize: "20px" }}>
                            クイズを作成する
                        </div>
                    </div>
                </Button>
            </Box>
        </MuiThemeProvider>
    )
}

interface MyDataProps {
    uuid: string,
}
interface quizData {
    data: firebase.firestore.DocumentData,
    id: string
}
let MyCreateData = (props: MyDataProps) => {
    const [myData, setmyData] = useState<quizData[]>([])
    const [loading, setloading] = useState(true)
    let getData = async (arg: string) => {
        const snapshot = await db.collection("quiz").doc("quiz").collection(arg).get()
        const mydata = snapshot.docs.map((element) => {
            return { data: element.data(), id: element.id }
        })
        setloading(false)
        setmyData(mydata)
    }
    useEffect(() => {
        getData(props.uuid)
        // eslint-disable-next-line 
    }, [props.uuid])

    return (
        <div>
            <Box py="5px" style={{ backgroundColor: thema.palette.secondary.main, marginInline: "12px", borderRadius: "10px" }} mt="15px" >
                <Box mx="30px" my="10px" style={{ backgroundColor: "#00000045", borderRadius: "10px", textAlign: "center" }}>
                    <div style={{ color: "white", padding: "5px", fontSize: "26px" }}>作成した問題</div>
                </Box>
                {loading && <Box width="100%" textAlign="center"><CircularProgress /></Box>}
                {myData.reverse().map((e, i) => <div key={i}>
                    <Box my="10px" mx="15px">
                        <Button component={Link} to={"/overview/" + e.id} fullWidth style={{ backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029", justifyContent: "flex-start", position: "relative" }}>
                            {/* FIXME 折り返し */}
                            <Box px="5px" style={{ width: "100%" }} >
                                <div style={{ fontSize: "20px", color: "white", width: "100%" }}>{e.data.title}</div>
                                <Box style={{ width: "100%", textAlign: "right" }}>
                                    <Time date={e.data.date && new Date(e.data.date)} />
                                </Box>
                            </Box>
                        </Button>
                    </Box>
                </div>)
                }
            </Box >
        </div >
    )
}