import { Avatar, Box, Button, CircularProgress, Grid, MuiThemeProvider } from "@material-ui/core"
import { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { useAppContext } from "../App"
import { thema } from "../thema"
import firebase from 'firebase'
import { db, functions } from "../Firebase"
import { CircleChart } from "./CircleChart"
import { GoodButton } from "../utils/GoodButton"
import BackGround from '../img/back.png'
import { Time } from "../utils/Time"
import { TwitterShareButton } from "react-share"
import { Twitter } from '@material-ui/icons';
import { ChipData, TagInput } from "../utils/TagInput"



interface OverViewParams {
    uid: string
}

export const OverView = () => {
    const { uid } = useParams<OverViewParams>()
    const { uuid } = useAppContext()
    return (
        <div>
            {uuid === "" && <OverViewCommonUI type={"general"} uid={uid}></OverViewCommonUI>}
            {uuid !== "" && <OverViewCommonUI type={uid.endsWith(uuid) ? "author" : "general"} uid={uid}></OverViewCommonUI>}
        </div>
    )
}

type OverViewType = "general" | "author"
interface OverViewCommonUIProps {
    type: OverViewType,
    uid: string
}
export const OverViewCommonUI = (props: OverViewCommonUIProps) => {
    const history = useHistory()
    const [data, setData] = useState<firebase.firestore.DocumentData>()
    const [loading, setloading] = useState(false)
    const [isNotContent, setisNotContent] = useState(false)
    const { uuid } = useAppContext()
    const [tags, setTags] = useState<string[]>([])
    const [hashtags, sethashtags] = useState<string[]>([])
    // hashTag.push("QuiQui")

    let deleteData = async (doc: string) => {
        if (window.confirm("削除しますか？")) {
            let asyncTask: Promise<any>[] = []
            asyncTask.push(db.collection("quiz").doc("quiz").collection(uuid).doc(doc).delete())
            asyncTask.push(functions.httpsCallable("answerLogic-deleteQuestion")({ uid: doc, uuid: uuid }))
            setloading(false)
            await Promise.all(asyncTask)
            history.push("/mypage")
        }
    }
    let updateTags = async (tags: ChipData[]) => {
        setTags(tags.map(tag => tag.label))
        await functions.httpsCallable("answerLogic-tags")({ uid: props.uid, uuid: uuid, tags: tags.map(tag => tag.label) })
    }
    useEffect(() => {
        let mounted = false
        let getData = async () => {
            let snapshot
            snapshot = await db.collection("answer").doc(props.uid).get()
            if (!snapshot.exists) {
                setisNotContent(true)
            }
            if (snapshot.exists && mounted) {
                setData(snapshot.data())
                setTags(snapshot.data()?.tags ?? [])
                setloading(true)
            }

        }
        mounted = true
        getData()
        return () => { mounted = false }
    }, [props.uid])
    const questionNumber = () => {
        return data?.quizSize ?? "---"
    }
    const answerPeopleNumber = () => {
        return data?.rate?.length ?? "---"
    }

    useEffect(() => {
        let hashtags = tags ?? []
        hashtags.push("QuiQui")
        sethashtags(hashtags)
    }, [tags])

    const rate = () => {
        if (data?.rate?.length === 0) {
            return 0
        }
        let incorrectTotal = data?.rate?.map((i: { incorrect: number }) => { return i.incorrect }).reduce((previousValue: number, currentValue: number) => previousValue + currentValue)
        let quizTotalSize = data?.quizSize * data?.rate?.length
        return Math.round(((quizTotalSize - incorrectTotal) / quizTotalSize) * 100)
    }
    return (
        <MuiThemeProvider theme={thema}>
            {isNotContent && <Box>
                このクイズは削除されているか、存在しないIDです。
                <Button component={Link} to={"/"} fullWidth style={{ fontSize: "20px", padding: "5px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }}>
                    トップに戻る
                </Button>
            </Box>}
            {
                !isNotContent && loading &&
                <div style={{
                    backgroundColor: props.type === "author" ? thema.palette.secondary.main : thema.palette.primary.main,
                    marginInline: "12px", marginTop: "10px", borderRadius: "10px", textAlign: "center", paddingTop: "5px", paddingBottom: "10px"
                }}>
                    <Box style={{ backgroundColor: "#00000045", marginInline: "20px", borderRadius: "10px", marginBlock: "10px", paddingBlock: "10px" }}>
                        <div style={{ color: "#FFFFFF9B", fontSize: "15px", position: "relative" }}>{data?.user}</div>
                        <div style={{ color: "white", fontSize: "26px", paddingInline: "15px" }}>{data?.title}</div>
                        <Box style={{ color: "#FFFFFF9B", fontSize: "15px", position: "relative" }} >
                            <Time date={new Date(data?.date)} />
                            <TagInput tags={data?.tags} isEditor={props.type === "author"} setTags={updateTags} />
                            <Avatar src={data?.userPhoto} style={{ width: "20px", height: "20px", position: "absolute", bottom: "3px", right: "6px" }} alt="Twitter Avator" />
                        </Box>
                    </Box>
                    <div style={{ marginInline: "20px", marginBlock: "10px" }}>
                        <Button component={Link} to={"/quiz/" + data?.uuid + "/" + data?.uid} fullWidth style={{ fontSize: "20px", color: "white", padding: "5px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }}>
                            {
                                props.type === "author" ?
                                    "クイズを確認する" : "クイズに挑戦する"
                            }
                        </Button>
                    </div>

                    <OverviewComponent goodCount={data?.count} answerCount={answerPeopleNumber()} questionNumber={questionNumber()} correctRate={rate().toString()} />

                    <div style={{ marginInline: "20px", marginBlock: "10px" }}>


                        <TwitterShareButton style={{ width: "100%", fontSize: "20px", color: "white", padding: "5px", backgroundColor: "#1DA1F2", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }} url={"https://quiqui.tb-works.com/overview/" + props.uid} title={data?.title} hashtags={hashtags}
                            related={["quiqui_info"]} via={"みんなで作るクイズアプリQuiQui"}>
                            <span style={{ marginInline: "10px" }}>
                                Twitterで共有
                                <Twitter style={{ paddingInlineStart: "10px", verticalAlign: "middle" }} />
                            </span>

                        </TwitterShareButton>

                    </div>
                    <div style={{ marginInline: "20px", marginBlock: "10px" }}>
                        <GoodButton uid={props.uid} count={data?.count} />
                    </div>
                    {data?.rate.length !== 0 && <OverviewCircleGraph rate={data?.rate} quizSize={data?.quizSize} />}
                    <div style={{ marginInline: "20px", marginBlock: "10px" }}>
                        {
                            props.type === "author" ?
                                <Button onClick={() => deleteData(props.uid)} fullWidth style={{ fontSize: "25px", color: "white", padding: "0px", backgroundColor: "#D03333", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }}>
                                    このクイズを削除
                                </Button> :
                                <Button component={Link} to={"/quiz/" + data?.uuid + "/" + data?.uid} fullWidth style={{ fontSize: "25px", color: "white", padding: "0px", backgroundColor: "#FFFFFF3D", borderRadius: "10px", boxShadow: "0px 3px 6px #00000029" }}>
                                    クイズに挑戦する
                                </Button>
                        }
                    </div>
                </div>
            }
            {
                !isNotContent && !loading &&
                <div style={{ textAlign: "center", marginTop: "10px" }} >
                    <CircularProgress size={60} />
                </div>
            }
        </MuiThemeProvider >
    )
}

interface OverviewComponentProps {
    goodCount: string,
    answerCount: string,
    questionNumber: string,
    correctRate: string
}

export const OverviewComponent = (props: OverviewComponentProps) => {
    return (
        <MuiThemeProvider theme={thema}>
            <Box style={{
                backgroundImage: `url(${BackGround})`, backgroundSize: "cover",
                backgroundColor: "white", marginInline: "20px", paddingInline: "30px", paddingBlock: "5px", paddingBottom: "10px", borderRadius: "10px"
            }}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box style={{ fontSize: "36px", color: thema.palette.primary.main }}>
                            {props.answerCount}人
                            <Box style={{ fontSize: "12px", color: "grey" }} mt="-10px">
                                回答数
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{ fontSize: "36px", color: thema.palette.secondary.main }} mt="8px">
                            {props.goodCount}
                            <Box style={{ fontSize: "12px", color: "grey" }} mt="-8px">
                                いいね
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{ fontSize: "36px", color: thema.palette.info.main }}>
                            {props.questionNumber}問
                            <Box style={{ fontSize: "12px", color: "grey" }} mt="-10px">
                                問題数
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{ fontSize: "36px", color: thema.palette.success.main }}>
                            {props.correctRate}％
                            <Box style={{ fontSize: "12px", color: "grey" }} mt="-10px">
                                正解率
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </MuiThemeProvider>
    )
}
interface OverviewCircleGraphProps {
    rate: [{
        incorrect: number
    }],
    quizSize: number
}
export const OverviewCircleGraph = (props: OverviewCircleGraphProps) => {
    const groupBy = props.rate.reduce((result: any, current: any) => {
        const element = result.find((value: { incorrect: number }) => value.incorrect === current.incorrect);
        if (element) {
            element.value++;
        } else {
            result.push({
                incorrect: current.incorrect,
                value: 1,
            })
        }
        return result;

    }, []);
    let sort = groupBy.sort((a: { incorrect: number }, b: { incorrect: number }) => {
        return (a.incorrect < b.incorrect) ? -1 : 1;  //オブジェクトの昇順ソート
    });
    const data = sort.map((i: { incorrect: number; value: number }) => {
        return {
            title: (i.incorrect === 0) ? "全問正解" : i.incorrect.toString() + "問ミス",
            value: i.value
        }
    })
    return (
        <Box style={{
            backgroundImage: `url(${BackGround})`, backgroundSize: "cover",
            backgroundColor: "white", marginInline: "20px", paddingInline: "30px", paddingBlock: "5px", paddingBottom: "10px", borderRadius: "10px"
        }}>
            <CircleChart data={data} />
        </Box>
    )
}