import { Chip } from "@material-ui/core"
import TodayIcon from '@material-ui/icons/Today';

interface TimeProps {
    date?: Date
}
export const Time = (props: TimeProps) => {
    return (
        <Chip icon={<TodayIcon />} label={props.date?.toLocaleDateString() ?? "---"} color="primary" size="small" style={{ backgroundColor: "#0000001D", paddingInlineStart: "2px" }}></Chip>
    )
}